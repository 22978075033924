import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import monitorService from "../services/monitorService";
import { useEffect } from "react";
import { useClientStore } from "../stores/clientStore";
import { useUser } from "@auth0/nextjs-auth0/client";

const useSetFavoriteClient = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const userOrganizationId = user?.org_id || "";

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ monitorId, isFavorite }: { monitorId: string, isFavorite: boolean }) =>
      monitorService.setFavoriteClient(monitorId, isFavorite),
    onSuccess: (data: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Mutation Success:", data);
      }
      queryClient.invalidateQueries({ queryKey: ["allClients"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useSetGroupOnAccount = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const userOrganizationId = user?.org_id || "";

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ monitorId, groupId }: { monitorId: string, groupId: string | null }) =>
      monitorService.setGroupOnAccount(monitorId, groupId),
    onSuccess: (data: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Mutation Success:", data);
      }
      queryClient.invalidateQueries({ queryKey: ["allClients"] });
      queryClient.invalidateQueries({ queryKey: ["monitorClient", data.monitorId] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useCreateMonitorLink = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (data: any) =>
      monitorService.createMonitorLink(data),
    onSuccess: (data: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Mutation Success:", data);
      }
      queryClient.invalidateQueries({
        queryKey: ["allClients"],
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useDeleteMonitorLink = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const userOrganizationId = user?.org_id || "";
  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ monitorId }: { monitorId: string }) =>
      monitorService.deleteMonitorLink(monitorId, userOrganizationId),
    onSuccess: (data: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Mutation Success:", data);
      }
      queryClient.invalidateQueries({ queryKey: ["allClients"] });
      queryClient.invalidateQueries({ queryKey: ["organizationProfile"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useGetMonitorClient = ({ monitorId }: { monitorId: string }) => {
  const { user } = useUser();
  const userOrganizationId = user?.org_id || "";
  const { setDeepClientView } = useClientStore();
  const { data, error, isPending, isError, isSuccess } = useQuery({
    queryKey: ["monitorClient", monitorId],
    queryFn: () =>
      monitorService.getMonitorClient(monitorId, userOrganizationId),
    staleTime: 10 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!monitorId,
  });

  useEffect(() => {
    if (isSuccess && data) {
      setDeepClientView(data, "success");
    }
  }, [isSuccess, data, setDeepClientView]);

  if (error) {
  }
  return {
    data,
    isPending: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

const useUpdateMonitorAssignees = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const userOrganizationId = user?.org_id || "";

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ monitorId, data }: { monitorId: string; data: any }) =>
      monitorService.updateMonitorAssignees(
        monitorId,
        userOrganizationId,
        data
      ),
    onSuccess: (data: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Mutation Success:", data);
      }
      queryClient.invalidateQueries({ queryKey: ["allClients"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

export {
  useSetFavoriteClient,
  useSetGroupOnAccount,
  useCreateMonitorLink,
  useGetMonitorClient,
  useUpdateMonitorAssignees,
  useDeleteMonitorLink,
};
